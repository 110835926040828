import React from "react"
import Layout from "../../components/Layout/Layout"
import RestMenu from "../../components/Sections/RestMenu";


export default function Listok() {
    return (
        <Layout>
            <div className={"pt-5"}>
                <RestMenu/>
            </div>
        </Layout>
    );
}